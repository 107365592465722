import React from "react"
import Emoji from "../Emoji"
import {
  Wrapper,
  Left,
  Right,
  HandlesSection,
  HeadshotAndHandles,
  Paragraph,
  List,
  ListItem,
  R,
  G,
  MG,
  Y,
  B,
  BLK,
} from "./AboutStyles"
import { StaticImage } from "gatsby-plugin-image"
import blueBanner from "../../banner/banner-blue.svg"
import BrushedHeader from "../BrushedHeader"

import css from "@emotion/css"

const HeadshotCSS = css`
  width: 200px;
  height: 240px;
  -webkit-border-radius: 90px;
  -khtml-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  overflow: hidden;
  z-index: 1;
  margin: 2.3rem;
`

export default function About() {
  return (
    <Wrapper>
      <Left>
        <BrushedHeader banner={blueBanner}>ABOUT</BrushedHeader>
        <HeadshotAndHandles>
          <StaticImage
            src="./../../images/me/me-2021.jpg"
            alt="photo of me :)"
            placeholder="blurred"
            css={HeadshotCSS}
          />
          {HandlesSection}
        </HeadshotAndHandles>
      </Left>
      <Right>
        <h2>
          <Emoji symbol="👋" label="wave" /> Hi there,
        </h2>
        <Paragraph>
          I’m currently a M.Eng student at <R>Cornell University</R> studying
          Computer Science and a Software Engineering Intern at <BLK>Meta</BLK>.
        </Paragraph>
        <Paragraph>
          I enjoy fiddling around with things — this sparked my enthusiasm in
          designing and building apps, websites and games in the frontend, as
          well as designing algorithms and backend architectures. I learn my
          craft from these side projects, where hours of frustration eventually
          become memorable lessons. These are lessons I not only bring forward
          with me but also share — I’ve enjoyed being a <B>TA</B> for five
          semesters.
        </Paragraph>
        <Paragraph>
          Outside tech, I’m a <MG>table-tennis player</MG>,{" "}
          <G>chess enthusiast</G> and a <Y>dragon dancer</Y>! You may find some
          clips scattered around this site... Feel free to reach out if you’re
          down for a game of <Emoji symbol="🏓" label="table-tennis" /> or{" "}
          <Emoji symbol="♟️" label="chess" />!
        </Paragraph>
        <Paragraph>
          I grew up in Hong Kong, but I'm currently based in the U.S. I hope to
          be moving around the world throughout life. Here are some places I’ve
          been to outside the U.S. — a list that I’m constantly looking to grow!
        </Paragraph>

        <List>
          <ListItem>
            <Emoji symbol="🇫🇷" label="france" /> Paris
          </ListItem>
          <ListItem>
            <Emoji symbol="🇮🇹" label="italy" /> Rome
          </ListItem>
          <ListItem>
            <Emoji symbol="🇻🇦" label="vatican city" /> Vatican City
          </ListItem>
          <ListItem>
            <Emoji symbol="🇧🇦" label="bosnia and herzegovina" /> Sarajevo
          </ListItem>
          <ListItem>
            <Emoji symbol="🇲🇾" label="malaysia" /> Kuala Lumpur
          </ListItem>
          <ListItem>
            <Emoji symbol="🇭🇷" label="croatia" /> Zagreb, Split, Zadar
          </ListItem>
          <ListItem>
            <Emoji symbol="🇬🇧" label="uk" /> London, Cambridge, Oxford
          </ListItem>
          <ListItem>
            <Emoji symbol="🇯🇵" label="japan" /> Tokyo, Kyoto, Osaka, Nagoya
          </ListItem>
          <ListItem>
            <Emoji symbol="🇦🇺" label="australia" /> Sydney, Melbourne, Brisbane,
            Cairns
          </ListItem>
          <ListItem>
            <Emoji symbol="🇨🇳" label="china" /> Shanghai, Hangzhou, Kunming,
            Yangshuo, Zhuhai
          </ListItem>
        </List>
      </Right>
    </Wrapper>
  )
}

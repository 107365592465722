import React from "react"
import styled from "@emotion/styled"
import FadeInSection from "./FadeInSection/FadeInSection"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import RowSection from "./RowSection/RowSection"
import ScrollAnim from "./ScrollAnim"
import { css } from "@emotion/core"
import BrushedHeader from "./BrushedHeader"

import BrownBanner from "../banner/banner-brown.svg"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const Padding = styled.div`
  height: 10rem;
  width: auto;
`

const SmallScreenCSS = css`
  display: none;
  @media (max-width: 870px) {
    display: block;
  }
`

const LargeScreenCSS = css`
  display: block;
  @media (max-width: 870px) {
    display: none;
  }
`

export default function Projects({ data }) {
  return (
    <Content>
      <FadeInSection>
        <BrushedHeader banner={BrownBanner}>
          {"projects".toUpperCase()}
        </BrushedHeader>
      </FadeInSection>
      {data.allMarkdownRemark.edges
        .filter(({ node }) => {
          // Filter out future posts
          const rawDate = node.frontmatter.rawDate
          const date = new Date(rawDate)
          return date < new Date()
        })
        .filter(({ node }) => {
          // Select section to display content in
          const type = node.frontmatter.type
          return type === "projects"
        })
        .map(({ node }) => {
          const nf = node.frontmatter
          return (
            <React.Fragment key={`proj-${node.id}`}>
              <div css={SmallScreenCSS}>
                <FadeInSection key={node.id}>
                  <OutboundLink href={nf.path}>
                    <RowSection nf={nf} node={node} type={"projects"} />
                  </OutboundLink>
                </FadeInSection>
              </div>
              <div css={LargeScreenCSS}>
                {nf.spritesheet ? (
                  <ScrollAnim
                    spritesheetUrl={nf.spritesheet.childImageSharp.original.src}
                    frames={nf.frames}
                    width={nf.width}
                    height={nf.height}
                    id={node.id}
                    nf={nf}
                    node={node}
                  />
                ) : (
                  <FadeInSection key={node.id}>
                    <OutboundLink href={nf.path}>
                      <RowSection nf={nf} node={node} type={"projects"} />
                    </OutboundLink>
                  </FadeInSection>
                )}
                <Padding />
              </div>
            </React.Fragment>
          )
        })}
    </Content>
  )
}

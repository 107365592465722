import React from "react"
import { usePalette } from "react-palette"
import strToStackSvg from "../../scripts/strToStackSvg"
import { Box, useColorModeValue, AspectRatio } from "@chakra-ui/react"
import { css } from "@emotion/core"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  Row,
  LeftIcon,
  RightContent,
  HeaderWrapper,
  MarkerHeader,
  HeaderContainer,
  Role,
  TimePeriod,
  Summary,
} from "./RowSectionStyles"

export default function RowSection({ nf, node, type }) {
  const imgPath = nf.image.childImageSharp.original.src
  const { data } = usePalette(imgPath)

  return (
    <Row key={node.id}>
      <LeftIcon>
        <AspectRatio
          ratio={1}
          boxSize={type === "projects" ? "180px" : "180px"}
        >
          <Box
            css={css`
              border-radius: 25px !important;
            `}
            position="relative"
            maxHeight="100%"
            maxWidth="100%"
            overflow="hidden"
            boxShadow={`inset 0 0 20px 5px ${useColorModeValue(
              `${data.darkVibrant}19`,
              `${data.lightVibrant}19`
            )}`}
          >
            <Box
              bg={useColorModeValue(data.darkVibrant, data.lightVibrant)}
              border="1px solid"
              position="absolute"
              borderColor={useColorModeValue(
                `${data.darkVibrant}`,
                `${data.lightVibrant}`
              )}
              top={0}
              bottom={0}
              left={0}
              right={0}
              opacity={useColorModeValue(0.15, 0.25)}
            />
            <GatsbyImage image={getImage(nf.image)} alt={nf.title} />
          </Box>
        </AspectRatio>
      </LeftIcon>

      <RightContent>
        <HeaderContainer>
          <HeaderWrapper>
            <MarkerHeader>{nf.title}</MarkerHeader>
          </HeaderWrapper>
          {nf.techstack && strToStackSvg(nf.techstack)}
        </HeaderContainer>
        {nf.role && <Role>{nf.role}</Role>}
        <div>
          <TimePeriod>{nf.period}</TimePeriod>
        </div>
        <Summary>
          <span>{nf.summary}</span>
        </Summary>
      </RightContent>
    </Row>
  )
}

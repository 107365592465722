import React from "react"
import styled from "@emotion/styled"

// Icons
import DjangoFull from "../assets/stack/full/django.svg"
import NodeJSFull from "../assets/stack/full/nodejs.svg"
import TypescriptFull from "../assets/stack/full/typescript.svg"
import LibGDX from "../assets/stack/full/libgdx.svg"

import CPP from "../assets/stack/cpp.svg"
import CUGL from "../assets/stack/cugl.svg"
import Csharp from "../assets/stack/c-sharp.svg"
import Azure from "../assets/stack/microsoft-azure.svg"
import Box2D from "../assets/stack/box2d.svg"
// import Django from "../assets/stack/django.svg"
import Firebase from "../assets/stack/firebase.svg"
import Gatsby from "../assets/stack/gatsby.svg"
import GoogleCloud from "../assets/stack/google-cloud.svg"
import Java from "../assets/stack/java.svg"
import Javascript from "../assets/stack/javascript.svg"
// import NodeJS from "../assets/stack/nodejs.svg"
import OCaml from "../assets/stack/ocaml.svg"
import MyReactIcon from "../assets/stack/reactIcon.svg"
import Swift from "../assets/stack/swift.svg"
// import Typescript from "../assets/stack/typescript.svg"

import "./strToStackSvg.css"

const TechStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.4rem;
  height: 1.6rem;
  line-height: 2rem;
  font-weight: bold;
  @media (max-width: 870px) {
    margin-left: 0rem;
    height: 1.4rem;
  }
  & > * {
    /* Styles for each icon */
    height: inherit;
    width: auto;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
  transition: all 0.2s ease-in-out;
`
const strToStackSvg = (string) => {
  const iconStrings = string.split(", ").filter((str) => str.length > 0)
  // .map((str) => str.toLowerCase())
  // .sort()
  return (
    <TechStack>
      {iconStrings.map((iconStr) => {
        const str = iconStr.toLowerCase()
        if (str.includes("django")) return <DjangoFull key={str} />
        if (str.includes("firebase")) return <Firebase key={str} />
        if (str.includes("gatsby")) return <Gatsby key={str} />
        if (str.includes("google")) return <GoogleCloud key={str} />
        if (str.includes("javascript")) return <Javascript key={str} />
        if (str === "java") return <Java key={str} />
        if (str.includes("nodejs")) return <NodeJSFull key={str} />
        if (str.includes("ocaml")) return <OCaml key={str} />
        if (str === "react") return <MyReactIcon key={str} />
        if (str.includes("react")) return <MyReactIcon key={str} />
        if (str.includes("swift")) return <Swift key={str} />
        if (str.includes("typescript")) return <TypescriptFull key={str} />
        if (str.includes("libgdx")) return <LibGDX key={str} />
        if (str.includes("box2d")) return <Box2D key={str} />
        if (str.includes("c++")) return <CPP key={str} />
        if (str.includes("cugl")) return <CUGL key={str} />
        if (str.includes("c#")) return <Csharp key={str} />
        {
          /* if (str.includes("azure")) return <Azure key={str} /> */
        }
        return <span key={iconStr}>{iconStr}</span>
      })}
    </TechStack>
  )
}

export default strToStackSvg

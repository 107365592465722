import React from "react"
import PropTypes from "prop-types"
import LandingBio from "../components/LandingBio/landing-bio"
import Layout from "../components/Layout/layout"
import Details from "../components/Details"
import About from "../components/About/About"
import Other from "../components/Others/Other"

// import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <LandingBio />
    <About />
    <Details />
    <Other />
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage

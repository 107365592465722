import styled from "@emotion/styled"

const smallScreenThreshhold = "870px"

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 30px;
  align-items: flex-start;

  border-radius: 25px;
  border: 1px solid;
  border-color: #eff2f7;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  /* Space between Rows */
  margin: 0px 0px 40px 0px;

  @media (max-width: ${smallScreenThreshhold}) {
    flex-direction: column;
    align-items: stretch;
  }

  :hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transform: translateY(-4px);
    transition: all 0.25s ease 0s;
  }
`

const LeftIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${smallScreenThreshhold}) {
    margin-bottom: 1.5rem;
  }
`

const RightContent = styled.div`
  padding-left: 30px;
  flex-grow: 1;

  @media (max-width: ${smallScreenThreshhold}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const HeaderWrapper = styled.div`
  margin-bottom: 2px;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  border-radius: 1em 0 1em 0;
  /* background-image: linear-gradient(
    -100deg,
    rgba(255, 250, 150, 0.15),
    rgba(255, 250, 150, 0.8) 100%,
    rgba(255, 250, 150, 0.25)
  ); */
  @media (max-width: ${smallScreenThreshhold}) {
    margin-bottom: 0.7rem;
  }
`

const MarkerHeader = styled.h3`
  display: inline-block;
  font-weight: 800;
  margin-bottom: 0px;
  @media (max-width: ${smallScreenThreshhold}) {
    text-align: center;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${smallScreenThreshhold}) {
    flex-direction: column;
    margin-bottom: 0.6rem;
    & > * {
      /* Remove Tech Stack Margin */
      margin-left: 0px;
    }
  }
  & > * {
    /* Debugging */
    /* border: 1px solid black; */
  }
`

const Role = styled.div`
  line-height: 1.5rem;
  font-weight: bold;
`

const TimePeriod = styled.h5`
  display: inline;
  color: #606060;
  padding: 0px 0px 0px 20px;
`

const Summary = styled.div`
  padding: 10px 0px 0px 0px;
`

export {
  Row,
  LeftIcon,
  RightContent,
  HeaderWrapper,
  MarkerHeader,
  HeaderContainer,
  Role,
  TimePeriod,
  Summary,
}

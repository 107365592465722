import React, { useState, useRef, useEffect } from "react"
import "./FadeInSection.css"

// Inspired by
// https://dev.to/selbekk/how-to-fade-in-content-as-it-scrolls-into-view-10j4
// https://stackoverflow.com/questions/56158613/intersection-observer-only-observe-the-bottom-of-my-component-and-trigger-only-w
export default function FadeInSection(props) {
  const [isVisible, setVisible] = useState(true)
  const domRef = useRef()
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const bcr = entry.boundingClientRect
          const isBottomVisible = bcr.bottom < window.innerHeight && bcr.bottom
          setVisible(isBottomVisible || entry.isIntersecting)
        })
      },
      {
        threshold: 0.7,
      }
    )
    observer.observe(domRef.current)

    return () => observer.unobserve(domRef.current)
  }, [])
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  )
}

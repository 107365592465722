import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import FadeInSection from "./FadeInSection/FadeInSection"
import RowSection from "./RowSection/RowSection"
import Projects from "./Projects"
import DescriptionModal from "./DescriptionModal"

import BrushedHeader from "./BrushedHeader"
import YellowBanner from "../banner/banner-yellow.svg"
import GreenBanner from "../banner/banner-midnight-green.svg"

const CardContent = styled.div`
  padding: 0rem 1.45rem;
`

const SmallSectionDivider = styled.div`
  height: 5rem;
`

const ContentFormatted = ({ data }, typeFlag) => {
  return (
    <>
      <FadeInSection>
        <BrushedHeader
          banner={typeFlag === "experience" ? YellowBanner : GreenBanner}
        >
          {typeFlag.toUpperCase()}
        </BrushedHeader>
      </FadeInSection>
      <CardContent>
        {data.allMarkdownRemark.edges
          .filter(({ node }) => {
            // Filter out future posts
            const rawDate = node.frontmatter.rawDate
            const date = new Date(rawDate)
            return date < new Date()
          })
          .filter(({ node }) => {
            // Select section to display content in
            const type = node.frontmatter.type
            return type === typeFlag
          })
          .map(({ node }) => {
            const nf = node.frontmatter
            return (
              <FadeInSection key={node.id}>
                <DescriptionModal node={node}>
                  <RowSection nf={nf} node={node} type={typeFlag} />
                </DescriptionModal>
              </FadeInSection>
            )
          })}
      </CardContent>
    </>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          html
          id
          frontmatter {
            title
            date(formatString: "MMM YYYY")
            rawDate: date
            period
            type
            summary
            path
            role
            techstack
            spritesheet {
              childImageSharp {
                original {
                  src
                }
              }
            }
            frames
            width
            height
            image {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  blurredOptions: { width: 200 }
                  placeholder: BLURRED
                  width: 200
                  aspectRatio: 1.5
                  quality: 100
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`

const Details = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <div>
        <SmallSectionDivider />
        {ContentFormatted({ data }, "experience")}
        <SmallSectionDivider />
        {ContentFormatted({ data }, "teaching")}
        <SmallSectionDivider />
        {Projects({ data })}
        <SmallSectionDivider />
      </div>
    )}
  />
)

export default Details

import React from "react"
import styled from "@emotion/styled"
import "./Wave.css"
import { css } from "@emotion/react"

const WaveBGWrapper = styled.div`
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -1;

  background: #a7dbed; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #fffde4,
    #a7dbed
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #fffde4,
    #a7dbed
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const SwellCSS = css`
  transform: translateY(100%);
  animation-name: slideIn;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 1s;
  /* easeOutBack */
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`

export default function Wave() {
  return (
    <WaveBGWrapper>
      <svg
        className="waves"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
        css={SwellCSS}
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </WaveBGWrapper>
  )
}

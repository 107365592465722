import React from "react"
import styled from "@emotion/styled"
import css from "@emotion/css"

import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogHeader = styled.h3`
  margin: 0;
`
const PeriodHeader = styled.h3`
  text-align: center;
  color: grey;
`

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <DialogHeader>{props.title}</DialogHeader>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const MarkdownContent = styled.div`
  h1 {
    line-height: 1.5;
    /* margin-top: 2.5em; */
  }
  h4 {
    margin-top: -0.8rem;
    color: gray;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  li {
    padding: 0;
  }
  hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  a {
    text-decoration: none;
    position: relative;

    background-image: linear-gradient(
      rgba(255, 250, 150, 0.8),
      rgba(255, 250, 150, 0.8)
    );
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
    &:hover {
      background-size: 100% 88%;
    }
  }

  a > code:hover {
    text-decoration: underline;
  }

  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 5rem;
  padding-top: 0;

  @media (max-width: 870px) {
    max-width: 600px;
    /* transition: all 1s ease 0s; */
  }
  @media (max-width: 600px) {
    max-width: 450px;
    /* transition: all 1s ease 0s; */
  }
`

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export default function DescriptionModal({ children, node }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div
        css={css`
          cursor: pointer;
        `}
        onClick={handleClickOpen}
      >
        {children}
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          title={node.frontmatter.title}
        ></DialogTitle>
        <DialogContent dividers>
          <PeriodHeader>{node.frontmatter.period}</PeriodHeader>
          <MarkdownContent dangerouslySetInnerHTML={{ __html: node.html }} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

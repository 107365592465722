import React from "react"
import { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import RowSection from "./RowSection/RowSection"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import FadeInSection from "./FadeInSection/FadeInSection"

gsap.registerPlugin(ScrollTrigger)

const Scene = styled.section`
  height: 100%;
  width: auto;
  background: #ffffff;
`

const AnimDiv = styled.div`
  height: ${(props) => props.bgheight + "px"};
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.bgwidth + "px"};
  width: 100%;
  background-image: url(${(props) => props.spritesheetUrl});
  background-repeat: no-repeat;
  background-position: 0 50%;
`

export default function ScrollAnim({
  spritesheetUrl,
  frames,
  width,
  height,
  id,
  nf,
  node,
}) {
  // HoneyHeist NEW Values
  const frame_count = frames - 1, // no. sprites - 1
    x_offset_value = width / 2, // image width / 2
    y_offset_value = 20 // smaller the faster

  const scrollAnimRef = useRef(null)
  const projRef = useRef(null)

  useEffect(() => {
    const projElement = projRef.current

    gsap.to(projElement.querySelector(`.scene-${id} .viewer`), {
      backgroundPosition: -x_offset_value * frame_count * 2 + "px",
      ease: "steps(" + frame_count + ")", // use a stepped ease for the sprite sheet
      scrollTrigger: {
        // markers: true,
        trigger: `.scene-${id}`,
        start: "center 60%",
        end: "+=" + frame_count * y_offset_value,
        pin: projElement,
        scrub: true,
      },
    })
  }, [])

  return (
    <div key={`proj-${id}`} ref={projRef}>
      <FadeInSection key={`${id}`}>
        <OutboundLink href={nf.path}>
          <RowSection nf={nf} node={node} type={"projects"} />
        </OutboundLink>
      </FadeInSection>
      <Scene className={`scene-${id}`} ref={scrollAnimRef}>
        <AnimDiv
          className="viewer"
          spritesheetUrl={spritesheetUrl}
          bgwidth={width}
          bgheight={height}
        />
      </Scene>
    </div>
  )
}

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Wave from "../Wave/Wave"
import JNgai from "../JNgai"
import { css } from "@emotion/react"

const OuterContainer = styled.div`
  display: flex;
  margin-left: -10rem;
  margin-right: -10rem;
  @media (max-width: 1400px) {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  @media (max-width: 1100px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  /* width: 100vw; */
  height: 100vh;
`

const Description = styled.p`
  padding: 0;
  font-size: 1.4rem;
  line-height: 2rem;
`

const NameHeader = styled.h1`
  font-size: 3.5rem;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  text-align: left;
  align-items: center;
`
const LeftContent = styled.div`
  display: flex;
  max-width: 60%;
  flex-direction: column;
  @media (max-width: 1400px) {
    max-width: 55%;
  }
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`

const RightContent = styled.div`
  display: block;
  width: 400px;
  @media (max-width: 1000px) {
    display: none;
  }
`

const Emph = styled.span`
  font-weight: bold;
`
const EmphYellow = styled.span`
  font-weight: bold;
  color: #eabb67;
`
const EmphMidnightGreen = styled.span`
  font-weight: bold;
  color: #114354;
`
const EmphGrey = styled.span`
  font-weight: bold;
  color: #847389;
`
const EmphBrown = styled.span`
  font-weight: bold;
  color: #2d080a;
`

const fadeDownIn = (delay) => css`
  opacity: 0;
  animation-name: fadeDownIn;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-delay: ${delay};
  will-change: opacity, transform;
  /* easeOutBack */
  animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);

  @keyframes fadeDownIn {
    0% {
      opacity: 0;
      transform: translate(0px, -20px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0px);
    }
  }
`

const LandingBio = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `}
    render={(data) => (
      <OuterContainer>
        <Wave />
        <Container>
          <LeftContent>
            <Description css={fadeDownIn("0ms")}>Hi, my name is</Description>
            <NameHeader css={fadeDownIn("100ms")}>
              {data.site.siteMetadata.title}
            </NameHeader>
            <Description css={fadeDownIn("200ms")}>
              I’m studying Computer Science at <Emph>Cornell University</Emph>,
              where my interests broadly span{" "}
              <EmphMidnightGreen>distributed systems</EmphMidnightGreen>,{" "}
              <EmphGrey>game design</EmphGrey> and{" "}
              <EmphBrown>algorithm analysis</EmphBrown>.
            </Description>
            <Description css={fadeDownIn("300ms")}>
              Previously, I was a Software Engineer Intern at{" "}
              <EmphYellow>Meta</EmphYellow>
            </Description>
          </LeftContent>
          <RightContent css={fadeDownIn("400ms")}>
            <JNgai />
          </RightContent>
        </Container>
      </OuterContainer>
    )}
  />
)

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
}

export default LandingBio

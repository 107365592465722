import React from "react"
import styled from "@emotion/styled"
import { AiFillLinkedin } from "@react-icons/all-files/ai/AiFillLinkedin"
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub"
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail"
import { AiOutlineFileText } from "@react-icons/all-files/ai/AiOutlineFileText"

const smallScreenThreshold = "1000px"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: -4rem;
  margin-right: -4rem;

  @media (max-width: ${smallScreenThreshold}) {
    flex-direction: column;
    margin-left: 0rem;
    margin-right: 0rem;
  }
`

const Right = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${smallScreenThreshold}) {
    margin-left: 1.45rem;
    margin-right: 1.45rem;
    margin-top: 1rem;
  }
`
const Left = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${smallScreenThreshold}) {
    flex-direction: column-reverse;
    align-items: unset;
  }
`
const Handles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  @media (max-width: ${smallScreenThreshold}) {
    flex-direction: row;
    margin-bottom: 20px;
  }
`
const HandleLink = styled.a`
  margin: 20px;
  font-size: 1.8rem;
`

const HandlesSection = (
  <Handles>
    <HandleLink href="mailto:justinngai238@gmail.com">
      <AiOutlineMail />
    </HandleLink>
    <HandleLink href="https://github.com/NgaiJustin">
      <AiFillGithub />
    </HandleLink>
    <HandleLink href="https://www.linkedin.com/in/ngaijustin/">
      <AiFillLinkedin />
    </HandleLink>
    <HandleLink href="https://drive.google.com/file/d/18BVOjR2rk35iKBOygCEB3OIJi3yPwbrv/view?usp=sharing">
      <AiOutlineFileText />
    </HandleLink>
  </Handles>
)

const HeadshotAndHandles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  -webkit-appearance: none;
`
const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 2rem;
`

const List = styled.ul`
  /* padding-left: 1rem; */
  margin-top: 0;
`
const ListItem = styled.li`
  list-style-type: none;
  padding: 0;
`

const R = styled.span`
  color: #ba1c1c;
  font-weight: 800;
`
const G = styled.span`
  color: #847389;
  font-weight: 800;
`
const MG = styled.span`
  color: #114354;
  font-weight: 800;
`
const Y = styled.span`
  color: #ffb021;
  font-weight: 800;
`
const B = styled.span`
  color: #37b8e5;
  font-weight: 800;
`
const BLK = styled.span`
  font-weight: 800;
`

export {
  Wrapper,
  Left,
  Right,
  HandlesSection,
  HeadshotAndHandles,
  Paragraph,
  List,
  ListItem,
  R,
  G,
  MG,
  Y,
  B,
  BLK,
}

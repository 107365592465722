import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import BrushedHeader from "../BrushedHeader"
import greyBanner from "../../banner/banner-grey.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Emoji from "../Emoji"
import css from "@emotion/css"
import "./Other.css"

const OuterContainer = styled.div`
  margin: 0 0 100px 0;
  display: flex;
  flex-direction: column;
`

const normalImageCSS = css`
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
`

const featureImageCSS = css`
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  grid-column-end: span 2;
`

const query = graphql`
  query {
    allImageSharp(
      filter: { original: { src: { regex: "/other/" } } }
      sort: { fields: original___src, order: ASC }
    ) {
      edges {
        node {
          gatsbyImageData(placeholder: DOMINANT_COLOR)
          id
          original {
            src
          }
        }
      }
    }
  }
`

export default function Other() {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <OuterContainer>
          <BrushedHeader banner={greyBanner}>
            <Emoji symbol="🐉" label="dragon" />{" "}
          </BrushedHeader>
          <div className="image-grid">
            {data.allImageSharp.edges.map(({ node }, index) => {
              const hashedImgName = node.original.src
                .split("/")
                .pop()
                .split("-")
              const trueImgName = hashedImgName.slice(0, -1).join("-")
              const img = getImage(node.gatsbyImageData)
              const isFeatureImg = index % 3 === 0
              return (
                <GatsbyImage
                  image={img}
                  key={node.id}
                  loading={"lazy"}
                  alt={trueImgName}
                  css={isFeatureImg ? featureImageCSS : normalImageCSS}
                />
              )
            })}
            <p>Videos coming soon...</p>
          </div>
        </OuterContainer>
      )}
    />
  )
}
